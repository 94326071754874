import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image'
import { graphql, StaticQuery } from "gatsby"

const LOGO_PROYECTO_QUERY = graphql`
  {
    wordpress {
      page(id: "177", idType: DATABASE_ID) {
        template {
          ... on Wordpress_Template_Configuracion {
            templateName
            configuracion {
              logoProyecto {
                altText
                title
                srcSet
                sourceUrl
                sourceUrlSharp {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP, AVIF]
                      placeholder: NONE
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default function Logo() {
  return (
    <StaticQuery
      query={LOGO_PROYECTO_QUERY}
      render={(data) =>{
        const template = data.wordpress.page.template.configuracion;
        return (
          <>
            {
              template.logoProyecto &&
              <GatsbyImage className="nav-menu__logo" image={template.logoProyecto.sourceUrlSharp.childImageSharp.gatsbyImageData} alt="holamundo" />
            }
          </>
        )
      }

      }
    />
  );

}