import React  from "react"
import Layout from "../../components/Layout/LayoutNotHeader"
import Seo from "../../components/Layout/Seo"
import { graphql } from 'gatsby'
import SomosProyecto from "../../components/Proyecto/SomosProyecto"
import EnVentaProyecto from "../../components/Proyecto/EnVentaProyecto"
import EntregadosProyecto from "../../components/Proyecto/EntregadosProyecto"

export const query = graphql`
  query($id: ID!) {
    wordpress {
      page(id: $id, idType: DATABASE_ID) {
        title
        template {
          ... on Wordpress_Template_ProyectosEnVenta {
            proyectoEnVenta {
              indicadores {
                descripcion
                valor
              }
              logo {
                ...imagen
              }
              parrafo
              proyectosEnVenta {
                descripcionProyecto
                nombreProyecto
                textoVertical
                alinearTextoVertical
                imagenProyecto {
                  ...imagen
                }
              }
              proyectosEntregados {
                imagenProyecto {
                  ...imagen
                }
                nombreProyecto
                direccionProyecto
              }
            }
          }
        }
      }
    }
  }
  fragment imagen on Wordpress_MediaItem{
    altText
    title
    srcSet
    sourceUrl
    sourceUrlSharp {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
        )
      }
    }
  }  
`

export default function ProyectoEnVenta ({data}) {

  const page = data.wordpress.page;
  const template = page?.template?.proyectoEnVenta
  const pageTitle = page?.title
  return (
    <Layout>
      <Seo title={pageTitle} />
      <SomosProyecto
        logo={template.logo}
        parrafo={template.parrafo}
        indicadores={template.indicadores}
      />
      <EnVentaProyecto
        proyectos={template.proyectosEnVenta}
      />
      <EntregadosProyecto
        proyectos={template.proyectosEntregados}
      />
    </Layout>
  )

}