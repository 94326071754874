import * as React from "react"
import PropTypes from "prop-types"

const LayoutBlank = ({ children }) => {

  return (
    <main className="main-pdf">{ children}</main>
  )
}

LayoutBlank.propTypes = {
  children: PropTypes.node.isRequired,
}

LayoutBlank.defaultProps = {
  classEle: "mainsinnavgacion"
}

export default LayoutBlank