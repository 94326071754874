import React from 'react';
import { graphql, StaticQuery } from "gatsby"

const SOCIAL_QUERY = graphql`
  {
    wordpress {
      page(id: "177", idType: DATABASE_ID) {
        template {
          ... on Wordpress_Template_Configuracion {
            templateName
            configuracion {
             redes {
                clase
                link
              } 
            }
          }
        }
      }
    }
  }
`;

export default function Social() {
  return (
    <StaticQuery
      query={SOCIAL_QUERY}
      render={(data) =>{
        const configuracion = data.wordpress.page.template.configuracion;
        return (
          <>
            {
              configuracion.redes &&
                configuracion.redes.map((red,i)=>(
                  <a key={i} className="nav-menu__social-link" href={red.link} target="_blank" rel="noreferrer">
                    <i className={`nav-menu__social-icon ${red.clase} color-orange-100`}></i>
                  </a>
                ))
            }
          </>
        )
      }

      }
    />

  );

}