import React from 'react';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import InnerImageZoom from 'react-inner-image-zoom';

export default function MapaInicio({imagen, imagenMovil,puntos1,puntos2}) {
  return (
    <section className="grid-100 px-0 bg-yellow-100 ubicacion line-0 mov-px-0" id="ubicacion">
      <div className="grid-50 px-0 mov-px-0">
        {/*<GatsbyImage className="w-100" image={imagen.sourceUrlSharp.childImageSharp.gatsbyImageData} alt="holamundo" />*/}
        {
          imagen &&
          <InnerImageZoom className="only-desk" src={imagen.sourceUrl} zoomSrc={imagen.sourceUrl} />
        }
        {
          imagenMovil &&
          <InnerImageZoom zoomScale={.7} className="only-mov" src={imagenMovil.sourceUrl} zoomSrc={imagen.sourceUrl} />
        }
      </div>
      <div className="grid-50 px-0 pt-51 only-desk">
        <div className="mxw-650 mx-auto">
          <h3 className="font-sangbleu size-22 mb-38 color-green-100"><span className="color-orange-100">01.</span>&nbsp;&nbsp;&nbsp;&nbsp; <span className="font-poppins weight-600">Santa Isabel 169</span></h3>
          <div className="grid-100 px-0">
            <div className="grid-50 px-0">
              <div className="listing size-22 font-sangbleu color-green-100" dangerouslySetInnerHTML={{__html: puntos1}}/>
            </div>
            <div className="grid-50 px-0">
              <div className="listing size-22 font-sangbleu color-green-100" dangerouslySetInnerHTML={{__html: puntos2}}/>
            </div>
          </div>
        </div>
      </div>
    </section>
  );

}
