import React from 'react';

export default function VideoInicio({videoUrl}) {

  return (
    <section className="line-0">
      {
        videoUrl &&
        <iframe className="video-inicio" src={videoUrl} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      }
    </section>
  );

}