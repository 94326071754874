import React, { useRef, useState } from "react"
import Slider from "react-slick"
import { GatsbyImage } from "gatsby-plugin-image"

export default function ViveInicio({tituloBarrio,textoBarrio, galeriaBarrio, etiqueta,nro}) {

  const [activeSlide, setActiveSlide] = useState(0);
  const slider = useRef('');
  const countSlide = galeriaBarrio.length;


  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7000,
    pauseOnHover: false,
    pauseOnFocus: false,
    afterChange: current => setActiveSlide(current),
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          dots: true
        }
      }
    ]
  }

  const handleArrowSlick = () => {
    slider.current.slickGoTo(activeSlide - 1);
  }

  return (
    <section className="bg-yellow-100 px-81 pt-147 pb-140 flex pt-51 mov-block mov-px-50 mov-pb-88 mov-pt-35">
      <div className="w-128 shrink-0 relative">
        <div className="vertical mt-20 mov-mt-10 mov-l-n45">
          <span className="color-green-100 font-poppins size-16 mov-size-12 weight-600">{nro}.</span>
          <span className="vertical__line inline-block bg-green-100"/>
          <span className="color-green-100 font-poppins size-16 mov-size-12 weight-600">{etiqueta}</span>
        </div>
      </div>
      <div className="">
        <h2 className="size-70 color-orange-100 font-poppins weight-500 text-mt-0 mov-size-30"
            dangerouslySetInnerHTML={{ __html: tituloBarrio }} />
        <div className="size-22 font-sangbleu mov-size-16-2 mov-br-none mov-mb-38 color-blue-100 mov-text-mb-23-2 color-green-100" dangerouslySetInnerHTML={{ __html: textoBarrio }} />
        <div className="slider-nav mt-121 only-desk">
          <div className="slider-nav__line line bg-green-100 w-full"/>
          <button className="slider-nav__btn shrink-0" onClick={handleArrowSlick}>
            <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.15997 0.729492L1.75 7.08936L8.15997 12.9395" stroke="#477177" strokeWidth="3" strokeMiterlimit="10"/>
            </svg>
          </button>
          <button className="slider-nav__btn shrink-0" onClick={handleArrowSlick}>
            <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.810547 0.729492L7.22052 7.08936L0.810547 12.9395" stroke="#477177" strokeWidth="3" strokeMiterlimit="10"/>
            </svg>
          </button>
          <div className="color-green-100 size-22 font-sangbleu shrink-0"><span>{activeSlide + 1}</span>/<span>{countSlide}</span></div>
        </div>
      </div>
      <div className="mxw-644 ml-auto mov-mxw-100p">
        {settings &&
        <Slider {...settings} className="slick-arrow-none slick-dots--orange-100 mov-slick-dots-b-n42" ref={slider}>
          {galeriaBarrio &&
          galeriaBarrio.map((imagen, i) => (
            <div key={i}>
              {
                imagen &&
                <GatsbyImage
                  className="h-634 mxw-644 cover mov-mxw-100p mov-h-auto"
                  image={imagen.sourceUrlSharp.childImageSharp.gatsbyImageData}
                  alt={imagen.alt ? imagen.alt : imagen.title} />
              }
            </div>
          ))
          }
        </Slider>
        }
      </div>
    </section>
  )

}
