import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

export default function SomosProyecto({ logo, parrafo, indicadores }) {
  return (
    <section className="bg-orange-100 text-center pt-152 pb-69 mov-pt-100 mov-pb-52">
      <Link to="/" className="inline-flex absolute align-center back-link weight-bold size-15 spacing-029em">
        <svg width="13" height="20" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.4459 18.5414L3 10.0955L11.4459 1.64966" stroke="#1B2B3A" strokeWidth="3"  strokeMiterlimit="10"/>
        </svg>
        <span className="pl-18 font-sangbleu color-blue-100">VOLVER</span>
        </Link>
        {
          logo &&
          <GatsbyImage className="w-327 mov-w-180" image={logo.sourceUrlSharp.childImageSharp.gatsbyImageData}
                       alt="holamundo" />
        }
        <div className="size-22 mov-size-16 font-sangbleu color-black-100 px-370 mt-42 mb-94 mov-mt-33 mov-px-50 mov-mb-19" dangerouslySetInnerHTML={{ __html: parrafo }} />
        <Link to="/" className="hide-on-desktop">
          <span className="mov-size-16 text-decoration-underline font-sangbleu">LABOK.COM</span>
        </Link>
        <div className="grid-100 grid-parent px-79 mov-px-65 color-black-100">
          {indicadores &&
          indicadores.map((indicador, i) => (
            <div className="grid-33 px-0 pt-49 bd-t-3 mov-b-none mov-pt-37 color-black-100" key={i}>
              <h3 className="size-65 font-poppins mov-size-32 mov-poppins">{indicador.valor}</h3>
              <h4 className="size-22 pt-10 mov-size-20 mov-pt-0 weight-400 font-sangbleu mov-tenor-sans">{indicador.descripcion}</h4>
            </div>
          ))
          }
        </div>
    </section>
);

}
