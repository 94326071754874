import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import Slider from "react-slick"

export default function EntregadosProyecto({ proyectos }) {
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "arrow-orange-100 slick-prev slick-arrow " +
        (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0}
      type="button"
    >
      <svg
        width="18"
        height="33"
        viewBox="0 0 18 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17 32L2 16.5L17 1"
          stroke="#FF644D"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
      </svg>{" "}
    </button>
  )
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "arrow-orange-100 slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === (slideCount - 1)}
      type="button"
    >
      <svg
        width="18"
        height="33"
        viewBox="0 0 18 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 1L16 16.5L0.999998 32"
          stroke="#FF644D"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
      </svg>
    </button>
  )

  var settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
  }
  return (
    <>
      <section className="bg-yellow-100 only-desk">
        <h2 className="mov-w-wrap mx-79 mov-mx-50 pb-77 mov-pb-51 uppercase bd-top-orange-100 pt-50 font-poppins size-70 mov-size-42 color-orange-101 weight-400 text-left">
          Proyectos Entregados
        </h2>
        <div className="grid-100 flex grid-parent flex-wrap">
          {proyectos &&
            proyectos.map((proyecto, i) => (
              <div
                className="grid-33 color-orange-101 text-center mb-54 px-79"
                key={i}
              >
                {
                  proyecto.imagenProyecto &&
                  <GatsbyImage
                    className="w-full"
                    image={
                      proyecto.imagenProyecto.sourceUrlSharp.childImageSharp
                        .gatsbyImageData
                    }
                    alt="holamundo"
                  />
                }
                <h3 className="size-22 font-sangbleu mt-35">
                  {proyecto.nombreProyecto}
                </h3>
                <div
                  className="size-22 font-sangbleu mt-19"
                  dangerouslySetInnerHTML={{
                    __html: proyecto.direccionProyecto,
                  }}
                />
              </div>
            ))}
        </div>
      </section>
      <section className="only-mov bg-yellow-100 mov-px-50">
        <h2 className="mov-w-wrap mov-pb-51 uppercase font-poppins mov-poppins size-70 mov-size-42 color-orange-101 weight-400 text-left mov-text-center">
          Proyectos Entregados
        </h2>
        <Slider className="delivered-projects" {...settings}>
          {proyectos &&
            proyectos.map((proyecto, i) => (
              <div className="text-center color-orange-101" key={i}>
                {
                  proyecto.imagenProyecto &&
                  <GatsbyImage
                    image={
                      proyecto.imagenProyecto.sourceUrlSharp.childImageSharp
                        .gatsbyImageData
                    }
                    alt="holamundo"
                  />
                }
                <h3 className="size-22 font-sangbleu mt-35 mov-size-20">
                  {proyecto.nombreProyecto}
                </h3>
                <div
                  className="size-22 mov-size-15 font-sangbleu mt-19"
                  dangerouslySetInnerHTML={{
                    __html: proyecto.direccionProyecto,
                  }}
                />
              </div>
            ))}
        </Slider>
      </section>
    </>
  )
}
