import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image'
import { graphql, StaticQuery } from "gatsby"

const LOGO_QUERY = graphql`
  {
    wordpress {
      page(id: "177", idType: DATABASE_ID) {
        template {
          ... on Wordpress_Template_Configuracion {
            templateName
            configuracion {
              logo {
                altText
                title
                srcSet
                sourceUrl
                sourceUrlSharp {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP, AVIF]
                      placeholder: NONE
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default function Logo() {
  return (
    <StaticQuery
      query={LOGO_QUERY}
      render={(data) =>{
        const configuracion = data.wordpress.page.template.configuracion;
        return (
          <>
            {
              configuracion.logo &&
              <GatsbyImage className="nav-menu__logo" image={configuracion.logo.sourceUrlSharp.childImageSharp.gatsbyImageData} alt="holamundo" />
            }
          </>
        )
      }

      }
    />
  );

}