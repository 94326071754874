import React from "react"
import {CreateLocalLink, CrearItemsHijos} from "../../utils/Utils"
import UniversalLink from "./UniversalLink"

const MenuItem = ({ menuItem, wordPressUrl, modal}) => {
  const menuItemsChildrens = menuItem.childItems
  return (
    <li className="nav-menu__item" id={`nav-menu__item-${menuItem.id}`}>
      <UniversalLink
        menuItem={menuItem}
        to={CreateLocalLink(menuItem, wordPressUrl)}
        modal={modal}
      >
        <span className="nav-menu__text" dangerouslySetInnerHTML={{__html: menuItem.label}}/>
      </UniversalLink>

      {CrearItemsHijos(menuItemsChildrens, wordPressUrl)}
    </li>
  )
}

export default MenuItem