import * as React from "react"
import PropTypes from "prop-types"

import Footer from "../Footer/Footer"
import Header from "../Header/Header"

const LayoutNotHeader = ({ children }) => {

  return (
    <div>
      <Header classEle="only-mov"/>
      <main>{children}</main>
      <Footer/>
    </div>
  )
}

LayoutNotHeader.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutNotHeader