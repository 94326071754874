import React from 'react';
import { GatsbyImage } from "gatsby-plugin-image"

export default function ArquitectosInicio({imagen,imagenMov, titulo, texto, nro, etiqueta}) {
  return (
    <section className="">
      {
        imagen &&
        <GatsbyImage className="only-desk w-full" image={imagen.sourceUrlSharp.childImageSharp.gatsbyImageData} alt="holamundo" />
      }
      {
        imagenMov &&
        <GatsbyImage className="only-mov w-full mov-h-596" image={imagenMov.sourceUrlSharp.childImageSharp.gatsbyImageData} alt="holamundo" />
      }
      <div className="flex pb-125 absolute inset-t-l-r-0 px-81 pt-91 mov-px-50">
        <div className="w-128 shrink-0 relative mov-w-0">
          <div className="vertical mt-20 mov-pt-35 mov-vertical">
            <span className="color-yellow-100 font-poppins size-16 mov-size-12 weight-600">{nro}.</span>
            <span className="vertical__line inline-block bg-orange-100"/>
            <span className="color-yellow-100 font-poppins size-16 mov-size-12 weight-600">{etiqueta}</span>
          </div>
        </div>
        <div className="grid-100 px-0 mov-pt-300">
          <div className="grid-40 px-0">
            <h2 className="title-arqs mov-pr-40 color-yellow-100 size-52 mov-size-24 font-poppins weight-400 text-mt-0 text-mb-0 mb-35 mov-mb-13"
                dangerouslySetInnerHTML={{ __html: titulo }} />
            <div className="text-arqs mov-pr-20 size-22 mov-size-12-1 color-yellow-100 font-sangbleu mb-45 mov-mb-22 text-mb-0 mov-font-sangbleu mov-mxw-240" dangerouslySetInnerHTML={{ __html: texto }} />
            <a className="color-yellow-100 px-35 mov-size-12 py-15 mov-px-12 font-sangbleu btn-outline-yellow-100 inline-block" href="https://nomena.net/" target="_blank" rel="noreferrer">Visita nomena.net</a>
          </div>
          <div className="grid-60 px-0">
          </div>
        </div>
      </div>
    </section>
  );

}
