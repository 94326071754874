import React, { useRef, useState } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import ReactModal from "react-modal"
import Slider from "react-slick"
import Logo from "../Others/Logo"
import { Link } from "gatsby"

if (typeof(window) !== 'undefined') ReactModal.setAppElement('body');

// const customStyles = {
//   overlay: {
//     backgroundColor: '#FFF6E1',
//   },
// };

const customStyles = {
  overlay : {
    backgroundColor: '#FFF6E1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflowY: 'auto',
    overflowX: 'none',
  },
  content:{
    inset: 'initial',
    position: 'relative',
    margin: 'auto',
    border: 'initial',
    backgroundColor: 'transparent',
    overflow: 'initial'
  }
};


export default function EnVentaProyecto({proyectos}) {

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "arrow-orange-100 slick-prev slick-arrow " +
        (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0}
      type="button"
    >
      <svg width="31" height="56" viewBox="0 0 31 56" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M29.8168 54.9066L3 28.4533L29.8168 2" stroke="#FF644D" strokeWidth="3" strokeMiterlimit="10"/></svg>
    </button>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "arrow-orange-100 slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === (slideCount - 1)}
      type="button"
    >
      <svg width="31" height="56" viewBox="0 0 31 56" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.99959 2.0001L28.8164 28.4534L1.99959 54.9067" stroke="#FF644D" strokeWidth="3" strokeMiterlimit="10"/></svg>
    </button>
  );

  const sliderProyectos = useRef();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalClose = event => {
    setIsModalOpen(false)
  }

  const handleModalOpen = index => {
    setIsModalOpen(true);
    setTimeout(function(){
      sliderProyectos.current.slickGoTo(index);
    })
  }

  const settings2 = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 7000,
    pauseOnHover: false,
    fade: true,
    pauseOnFocus: false,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          dots: true
        }
      }
    ]
  }

  return (
    <>
      <section className="pt-50 bg-yellow-100 mov-pt-51 pb-105">
        <div className="grid-column-2 mov-grid-column-1">
          <h2 className="w-no-wrap mov-w-wrap uppercase mov-center mx-79 mov-mx-50 font-poppins mov-poppins size-70 mov-size-42 color-orange-101 pb-83 mov-pb-45 weight-400">Proyectos en Venta</h2>
          {proyectos &&
          proyectos.map((proyecto, i) => (
            <div className={`color-orange-101 text-center px-79 mov-px-50 mb-180 mov-pb-45 mov-mb-0 relative grid__item--${proyecto.alinearTextoVertical} `} key={i}>
              {
                proyecto.imagenProyecto &&
                <GatsbyImage className="h-520 mov-h-212 w-full pointer" onClick={()=> handleModalOpen(i)} image={proyecto.imagenProyecto.sourceUrlSharp.childImageSharp.gatsbyImageData} alt="holamundo" />
              }
              <h3 className="size-22 mov-size-20 mov-lh-23 font-sangbleu mt-35 mov-mt-23">{proyecto.nombreProyecto}</h3>
              <div className="size-22 mov-size-12 mov-lh-30 font-sangbleu text-vertical text-my-0" dangerouslySetInnerHTML={{__html: proyecto.textoVertical}}/>
              <div className="size-22 mov-size-16 mov-lh-22 font-sangbleu mov-mt-13 description-on-sale px-60" dangerouslySetInnerHTML={{ __html: proyecto.descripcionProyecto }} />
            </div>
          ))
          }
        </div>
      </section>
      <ReactModal
        isOpen={isModalOpen}
        onRequestClose={handleModalClose}
        contentLabel="Modal Proyectos"
        style={customStyles}
      >
        <div className="header--modal mov-px-16 flex align-center justify-space-between mov-pt-14 mov-pb-14 only-mov">
          <Link to="/">
            <Logo/>
          </Link>
          <button onClick={handleModalClose}>
            <svg className="mov-h-29" width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M42 42L1 1" stroke="#FF5D44" strokeWidth="2" strokeMiterlimit="10"/>
              <path d="M42 0.999999L1 42" stroke="#FF5D44" strokeWidth="2" strokeMiterlimit="10"/>
            </svg>
          </button>
        </div>
        <div className="modal--proyecto center-vertical mov-center-normal mov-px-16 mov-mt-80">
          <div className="text-right">
            <button onClick={handleModalClose} className="modal-cerrar only-desk">
              <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M42 42L1 1" stroke="#FF5D44" strokeWidth="2" strokeMiterlimit="10"/>
                <path d="M42 0.999999L1 42" stroke="#FF5D44" strokeWidth="2" strokeMiterlimit="10"/>
              </svg>
            </button>
          </div>
          <div className="mxw-1697 mx-auto my-auto mt-42 mov-mt-0">
            <Slider {...settings2} className="slick-arrow-x-n66 slick-dots--orange-100 mov-slick-dots-b-75 mov-slick-dots-relative" ref={sliderProyectos}>
              {proyectos &&
              proyectos.map((proyecto, i) => (
                <div key={i} className="mov-h-410">
                  <h3 className="size-32 mov-size-24 font-sangbleu text-center mb-38 mov-mb-24">{proyecto.nombreProyecto}</h3>
                  {
                    proyecto.imagenProyecto &&
                    <GatsbyImage
                      className="w-full h-712 mov-h-254"
                      image={proyecto.imagenProyecto.sourceUrlSharp.childImageSharp.gatsbyImageData}
                      alt={proyecto.imagenProyecto.alt ? proyecto.imagenProyecto.alt : proyecto.imagenProyecto.title} />
                  }
                  <h3 className="size-32 mov-size-20 font-sangbleu text-center mt-35 mov-mt-25" dangerouslySetInnerHTML={{__html: proyecto.textoVertical}}/>
                </div>
              ))
              }
            </Slider>
          </div>
        </div>
      </ReactModal>
    </>
  );

}
