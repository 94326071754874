import React from 'react';
import { graphql, StaticQuery } from "gatsby"

const SOCIAL_MODAL_QUERY = graphql`
  {
    wordpress {
      page(id: "177", idType: DATABASE_ID) {
        template {
          ... on Wordpress_Template_Configuracion {
            configuracion {
             correo
             redes {
                clase
                link
              } 
            }
          }
        }
      }
    }
  }
`;

export default function SocialModal() {
  return (
    <StaticQuery
      query={SOCIAL_MODAL_QUERY}
      render={(data) =>{
        const template = data.wordpress.page.template.configuracion;
        return (
          <>
            <a className="nav-menu__social-link" href={`mailTo:${template.correo}`} target="_blank" rel="noreferrer">
              <i className={`nav-menu__social-icon far fa-envelope color-orange-100`}></i>
            </a>
            {
              template.redes &&
              template.redes.map((red,i)=>(
                <a key={i} className="nav-menu__social-link" href={red.link} target="_blank" rel="noreferrer">
                  <i className={`nav-menu__social-icon ${red.clase} color-orange-100`}></i>
                </a>
              ))
            }
          </>
        )
      }

      }
    />

  );

}