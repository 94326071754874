import React from "react"
import { navigate } from "gatsby"

const UniversalLink = ({ children, to,modal, activeClassName, partiallyActive, menuItem, ...other}) => {

  return (
    <>
      {
          <a className="nav-menu__link" href={to} {...other}
             onClick={
               (e)=>{
                 e.preventDefault();
                 // navigate("/");

                 let ele = e.target.attributes[1].nodeValue;
                 if(document.querySelector(`${ele}`)){
                   modal(false);
                   const offsetTop = document.querySelector(`${ele}`).offsetTop - 30;
                   window.scroll({
                     top: offsetTop,
                     behavior: "smooth"
                   })
                 }else{
                   navigate(`/${ele}`);
                 }


                 // ele = ele.replace('#', '.');
               }
             }
          >
            {children}

          </a>
      }
    </>
  )
}
export default UniversalLink